<template>
  <div class="school-way-banners v2-container-small">
    <div class="school-way-banners__items">
      <div class="school-way-banners__item school-way-banners__item-1">
        <p>Верните <span>13%</span> от&nbsp;стоимости за&nbsp;обучение</p>
        <button
          class="m-btn"
          @click="toggleLeadModal"
        >
          <img
            src="/v2/school/ceny/banners/blue-arrow.svg"
            alt=">"
          >
          <span>Как это сделать?</span>
        </button>
      </div>
      <div class="school-way-banners__item school-way-banners__item-2">
        <p>
          Платить <span>частями</span> проще  с
          <img
            src="/v2/school/ceny/banners/split.svg"
            alt="split"
          >
        </p>
        <button
          class="m-btn"
          @click="toggleLeadModal"
        >
          <img
            src="/v2/school/ceny/banners/green-arrow.svg"
            alt=">"
          >
          <span>Разбить платеж</span>
        </button>
      </div>
      <div class="school-way-banners__item school-way-banners__item-3">
        <p>Оплатите обучение <br> <span>мат. капиталом</span></p>
        <button
          class="m-btn"
          @click="toggleLeadModal"
        >
          <img
            src="/v2/school/ceny/banners/pink-arrow.svg"
            alt=">"
          >
          <span>Узнать детали</span>
        </button>
      </div>
    </div>

    <div class="school-way-banners__item-4">
      <h6>
        <span>
          Особые скидки до 100% для более чем 50 социальных групп
        </span>
      </h6>
      <p>Узнайте размер своей скидки</p>
      <button
        class="m-btn"
        @click="toggleLeadModal"
      >
        Оставить заявку
      </button>
      <img
        src="/v2/school/ceny/banners/100.webp"
        alt="100%"
      >
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'SchoolWayBanners',
  methods: {
    ...mapActions('modals', {
      toggleLeadModal: 'toggleLead4',
    }),
  },
};
</script>

<style scoped lang="scss">

.school-way-banners {
  display: flex;
  flex-direction: column;
  gap: 30px;

  @include media-down(tablet) {
    gap: 8px;
  }

  .school-way-banners__items {
    overflow-x: auto;
    display: flex;
    gap: 30px;
    padding-bottom: 8px;
    margin-bottom: -8px;

    @include media-down(laptop) {
      width: calc(100% + 16px);
      margin-left: -8px;
      padding-inline: 8px;
      gap: 7px;
    }
  }

  .school-way-banners__item {
    width: 393px;
    height: 260px;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    padding: 20px;

    border: 2px solid rgba(36, 36, 36, 0.1);
    box-shadow: inset 8px 8px 30px rgba(87, 106, 135, 0.02), inset 2px 2px 10px rgba(87, 106, 135, 0.02);
    border-radius: 20px;

    background-size: cover;
    background-repeat: no-repeat;
    background-position: right bottom;

    @include media-down(tablet) {
      width: 326px;
      height: 240px;
    }

    p {
      font-weight: 700;
      font-size: 30px;
      line-height: 90%;
      letter-spacing: -0.03em;
      text-transform: uppercase;
      font-feature-settings: 'case' on;
      color: $black;

      @include media-down(tablet) {
        font-size: 24px;
      }
    }

    button {
      margin-top: auto;
      display: flex;
      align-items: center;
      gap: 10px;

      &:hover {
        text-decoration: underline;
      }

      span {
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        color: $black;
      }
    }
  }

  .school-way-banners__item-1 {
    background-image: url("/v2/school/ceny/banners/bg-1.webp");

    p span {
      color: #9579DA;
    }
  }

  .school-way-banners__item-2 {
    background-image: url("/v2/school/ceny/banners/bg-2.webp");

    p span {
      color: #01B94F;
    }
  }

  .school-way-banners__item-3 {
    background-image: url("/v2/school/ceny/banners/bg-3.webp");

    p span {
      color: #D56FAC;
    }
  }

  .school-way-banners__item-4 {
    height: 260px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 20px;
    padding: 20px 25px 25px 20px;
    overflow: hidden;

    background-size: cover;
    background-repeat: no-repeat;
    background-position: right bottom;
    background-image: url("/v2/school/ceny/banners/bg.webp");

    @include media-down(tablet) {
      height: 420px;
      padding: 20px 16px 16px;
      background-position: center;
    }

    h6 {
      position: relative;
      margin-bottom: 10px;
      max-width: 660px;

      font-style: normal;
      font-weight: 700;
      font-size: 36px;
      line-height: 110%;
      letter-spacing: -0.02em;
      text-transform: uppercase;
      font-feature-settings: 'case' on;
      color: $white-color;

      @include media-down(tablet) {
        margin-bottom: 15px;
        max-width: 312px;
        font-size: 24px;
      }

      &:before {
        content: '';
        display: block;
        position: absolute;
        width: 470px;
        height: 46px;
        left: -5px;
        top: -3px;

        background-color: #FA5019;
        box-shadow: 0 1.5px 36px rgba(0, 62, 135, 0.2);
        border-radius: 9px;

        @include media-down(tablet) {
          width: 318px;
          height: 30px;
          left: -4px;
          top: -2px;
        }
      }

      span {
        position: relative;
        z-index: 2;
      }
    }

    p {
      font-weight: 500;
      font-size: 35px;
      line-height: 100%;
      font-feature-settings: 'pnum' on, 'lnum' on;
      color: $gray-bg;

      @include media-down(tablet) {
        font-size: 20px;
        line-height: 130%;
      }
    }

    button {
      margin-top: auto;
      padding: 15px 30px 19px;
      background-color: #FF4F01;
      border-radius: 50px;

      font-weight: 700;
      font-size: 20px;
      line-height: 24px;
      font-feature-settings: 'pnum' on, 'lnum' on;
      color: $white-color;

      @include media-down(tablet) {
        width: 100%;
        padding: 16px 10px 20px;
      }
    }

    img {
      position: absolute;
      right: 48px;
      top: 4px;
      width: 580px;

      @include media-down(laptop) {
        width: 400px;
        right: 6px;
        top: 70px;
      }

      @include media-down(tablet) {
        width: 355px;
        top: 165px;
        left: 44%;
        transform: translateX(-50%);
      }
    }
  }
}

</style>
