/* eslint-disable object-curly-newline,max-len,vue/max-len */

const schoolLiteCard = {
  title: 'Школа «Лайт»',
  description: 'Вебинары и записи по всем школьным темам для тех, кто хочет подтянуть знания по конкретному предмету, не уходя из своей школы',
  labels: ['вебинары и записи уроков', 'без прикрепления'],
  price: 4750,
  link: '/online-school',
  installmentPriceText: 'цена в рассрочку',
};
const schoolComfortCard = {
  title: 'Школа «Комфорт»',
  description: 'Онлайн вебинары с сильными преподавателями и интерактивными уроками. Проверяем домашки и отвечаем на вопросы в чате',
  labels: [
    'вебинары и записи уроков',
    'заочно',
    'онлайн-аттестация',
    'с прикреплением',
  ],
  price: 7920,
  link: '/online-school',
  installmentPriceText: 'цена в рассрочку',
};
const schoolPremiumCard = {
  title: 'Школа «Премиум»',
  description: 'Онлайн обучение из любой точки мира в мини-группах по государственным стандартам',
  labels: [
    'живые онлайн-уроки',
    'до 15 человек',
    'заочно',
    'без/с прикреплением',
    'онлайн-аттестация',
  ],
  price: 19800,
  sticker: true,
  link: '/online-school',
  installmentPriceText: 'цена в рассрочку',
};
const proforientaciaCard = {
  title: 'Профориентация для школьников',
  description: 'Запишитесь на онлайн консультацию с экспертом. <span>Начните бесплатно</span> с профтеста сейчас',
  list: [
    'Профтест для 5-11 классов',
    '19 вопросов',
    'разбор результатов с экспертом',
  ],
  price: null,
  link: '/proforientacia',
  withAccentColor: true,
};
const plusCard = {
  title: 'Школа «Плюс»',
  description: 'Дополните тарифы «Лайт» и «Комфорт», чтобы обучение стало ещё полезнее и проще',
  list: [
    'Персональный тьютор и&nbsp;индивидуальный образовательный&nbsp;маршрут',
    'Психолог (до 4 встреч в месяц)',
    'Профориентация: индивидуальная карта развития талантов и&nbsp;компетенций',
  ],
  price: 4440,
  newPrice: 3990,
  link: '/online-school',
};

const egeCard = {
  title: 'Курсы ЕГЭ',
  description: 'Подготовим к ЕГЭ 2025 на максимум. Решаем реальные варианты экзаменов, даем лайфхаки решений и формулы',
  labels: ['вебинары', '1 и 2 части ЕГЭ'],
  price: '0',
  priceText: '₽',
  priceFromText: 'первый урок',
  installmentPriceText: 'по любому предмету',
  link: '/ege',
};
const ogeCard = {
  title: 'Курсы ОГЭ',
  description: 'Подготовим к ОГЭ 2025 на 4 и 5. Решаем реальные варианты экзаменов, даем лайфхаки решений и формулы',
  labels: ['вебинары', '1 и 2 части ОГЭ'],
  price: '0',
  priceText: '₽',
  priceFromText: 'первый урок',
  installmentPriceText: 'по любому предмету',
  link: '/oge',
};

const tutoringCard = {
  title: 'Репетиторство',
  description: 'Делаем вместе домашку, разбираем сложные темы и помогаем понять предмет, а не заучивать формулы',
  labels: ['уроки 1 на 1', '50 минут занятие'],
  priceText: '₽',
  discount: [
    '8 занятий - 10%',
    '16 занятий - 15%',
    '32 занятий - 20%',
    '64 занятий - 25%',
  ],
  price: 1000,
  link: '/repetitor',
};
const careerGuidanceCard = {
  title: 'Профориентация',
  // eslint-disable-next-line max-len,vue/max-len
  description: 'От полного непонимания, «что же дальше» до осознанного выбора вуза, направления и профессии мечты',
  list: [
    'Определит профессиональные склонности и таланты',
    'Составит индивидуальную карту развития',
    'Поможет подобрать специальность, вуз и факультет',
  ],
  priceText: '₽',
  price: 1667,
  newPrice: 1500,
};
const externalCard = {
  title: 'Экстернат',
  description: 'Самостоятельное изучение школьных предметов по сокращенной программе с поддержкой наставников',
  labels: [
    'уроки в записи',
    'прикрепление',
    '1 класс за полгода',
    '2 класса за год',
    'онлайн-аттестация',
  ],
  price: 1840,
  link: '/externat',
  installmentPriceText: 'цена в рассрочку',
};
const recordLessonCard = {
  title: 'Уроки в записи',
  description: 'Записи уроков по всем школьным предметам с 5 по 11 класс. Смотрите бесплатно и без ограничений.',
  list: [
    'Больше 100 уроков от классных преподавателей',
    'К каждому уроку есть презентация и&nbsp;конспект',
    'Для закрепления материала есть тренажёры с&nbsp;автопроверкой',
  ],
  priceText: '₽',
  cardButtonFunc: 'toggleLead1Modal',
  buttonText: 'Получить',
  price: 0,
};
const dzCard = {
  title: 'Помощь с ДЗ',
  description: 'Помогаем понять сложные темы по всем предметам с 5 по 11 класс. Объясняем теорию, помогаем найти ответ самостоятельно.',
  labels: ['через чаты', 'это НЕ ГДЗ'],
  priceText: '₽',
  cardButtonFunc: 'toggleLead2Modal',
  installmentPriceText: 'для учеников школы',
  price: 0,
  leadTitle: 'Помощь с домашними заданиями в онлайн-школе',
};
const schoolPsyhologist = {
  title: 'Школьный психолог',
  description: 'Помогаем справиться со стрессом перед экзаменами, лучше понять свои чувства и эмоции.',
  labels: ['консультации', 'онлайн формат'],
  priceText: '₽',
  cardButtonFunc: 'toggleLead2Modal',
  installmentPriceText: 'для учеников школы',
  price: 0,
  leadTitle: 'Консультация школьного психолога',
};
const languageCourses = {
  title: 'Языковые курсы',
  description: 'Курсы китайского, испанского, французского и итальянского языков для детей и взрослых, с нуля до разговорного уровня.',
  labels: ['занятия 1 на 1', 'много практики'],
  priceText: '₽',
  cardButtonFunc: 'toggleLead2Modal',
  price: 1000,
  leadTitle: 'Бесплатная консультация по языковым курсам',
};
const softSkillsCard = {
  title: 'Курсы Soft Skills',
  description: 'Помогаем развивать «гибкие» навыки детям с 8 до 18 лет. Онлайн занятия с экспертами в мини-группах.',
  labels: ['креативность', 'лидерство', 'коммуникация', 'мотивация'],
  priceText: '₽',
  cardButtonFunc: 'toggleLead2Modal',
  installmentPriceText: 'для учеников школы',
  price: 0,
  leadTitle: 'Бесплатная консультация по курсам Soft Skills',
};
const programmingCard = {
  title: 'Программирование',
  description: 'Курсы для детей с 5 по 11 класс по Roblox, Mincraft, Python и C++ с упорок на практику.',
  labels: ['много практики', 'hard skills'],
  cardButtonFunc: 'toggleLead2Modal',
  leadTitle: 'Бесплатная консультация по курсам программирования',
};
const universityCard = {
  title: 'Университет «Синергия»',
  description: 'Дистанционные и очные программы бакалавриата и магистратуры в топ-3 лучших вузов страны по версии hh.ru.',
  labels: ['125 программ', 'государственный диплом'],
  priceText: ' ',
  cardButtonFunc: 'toggleLead2Modal',
  installmentPriceText: 'для учеников школы',
  priceFromText: 'скидка',
  price: '30%',
  leadTitle: 'Бесплатная консультация по поступлению в вуз',
};
const secondDiploma = {
  title: 'Двойной диплом',
  description: 'Программы двойного диплома с международными университетами: Китая, Дубая и Сербии.',
  labels: ['карьерный буст', 'языковая практика'],
  cardButtonFunc: 'toggleLead2Modal',
  leadTitle: 'Консультация по получению двойного диплома',
};
const collegeCard = {
  title: 'Колледж «Синергия»',
  description: 'Программы колледжа рассчитаны на выпускников 9-х и 11-х классов. По окончании вы получите среднее профессиональное образование.',
  labels: ['36 программ', 'государственный диплом'],
  priceText: ' ',
  cardButtonFunc: 'toggleLead2Modal',
  installmentPriceText: 'для учеников школы',
  priceFromText: 'скидка',
  price: '30%',
  leadTitle: 'Бесплатная консультация по поступлению в колледж',
};

export const mainData = [
  {
    age: '5-7',
    color: '#FBD277',
    btnColor: '#FF7233',
    girlImage: true,
    girlBtnColor: '#A8FF53',
    accentTextColor: '#FF7233',
    colorClassName: 'orange',

    cards: [
      // { ...schoolLiteCard },
      { ...tutoringCard, link: '/catalog/repetitor' },
      { ...schoolComfortCard },
      { ...schoolPremiumCard, stickerUrl: '/v2/global/black-friday/top-sale-violet.svg' },
      // { ...tutoringCard },
      { ...proforientaciaCard },
      { ...externalCard },
    ],
  },
  {
    age: '8-9',
    girlImage: false,
    color: '#B4DF80',
    accentTextColor: '#B4DF80',
    btnColor: '#A8FF53',
    colorClassName: 'green',

    cards: [
      // { ...schoolLiteCard },
      { ...tutoringCard, link: '/catalog/repetitor' },
      { ...schoolComfortCard },
      { ...schoolPremiumCard, stickerUrl: '/v2/global/black-friday/top-sale-blue.svg' },
      // { ...tutoringCard },
      { ...proforientaciaCard },
      { ...externalCard },
      { ...ogeCard },
    ],
  },
  {
    age: '10-11',
    girlImage: false,
    color: '#9FE3FF',
    accentTextColor: '#9FE3FF',
    btnColor: '#41E8FF',
    colorClassName: 'blue',

    cards: [
      // { ...schoolLiteCard },
      { ...tutoringCard, link: '/catalog/repetitor' },
      { ...schoolComfortCard, price: 11090 },
      { ...schoolPremiumCard, stickerUrl: '/v2/global/black-friday/top-sale-pink.svg', price: 21390 }, // price: 24990,
      // { ...tutoringCard },
      { ...proforientaciaCard },
      { ...externalCard }, // price: 5780,
      { ...egeCard },
    ],
  },
];

export const schoolPackages = [
  {
    color: '#FBD277',
    btnColor: '#FF7233',
    girlImage: true,
    girlBtnColor: '#A8FF53',
    accentTextColor: '#FF7233',
    colorClassName: 'orange',

    cards: [
      { ...tutoringCard },
      { ...externalCard },
      { ...plusCard, newPrice: null, price: 3990, installmentPriceText: 'цена в рассрочку' },
      // { ...careerGuidanceCard, newPrice: null, price: 1500 },
      { ...proforientaciaCard },
      { ...recordLessonCard },
    ],
  },
  {
    color: '#B4DF80',
    btnColor: '#A8FF53',
    girlImage: true,
    girlBtnColor: '#FF7233',
    accentTextColor: '#B4DF80',
    colorClassName: 'green',

    cards: [
      { ...tutoringCard },
      { ...externalCard },
      { ...ogeCard },
      // { ...careerGuidanceCard, newPrice: null, price: 1500 },
      { ...proforientaciaCard },
      { ...plusCard, newPrice: null, price: 3990, installmentPriceText: 'цена в рассрочку' },
    ],
  },
  {
    color: '#9FE3FF',
    btnColor: '#41E8FF',
    girlImage: true,
    girlBtnColor: '#C972FF',
    accentTextColor: '#9FE3FF',
    colorClassName: 'blue',

    cards: [
      { ...tutoringCard },
      { ...externalCard }, // price: 5780,
      { ...egeCard },
      // { ...careerGuidanceCard, newPrice: null, price: 1500 },
      { ...proforientaciaCard },
      { ...plusCard, newPrice: null, price: 3990, installmentPriceText: 'цена в рассрочку' },
    ],
  },
];

export const schoolComPackages = [
  {
    color: '#FBD277',
    btnColor: '#FF7233',
    girlImage: true,
    girlBtnColor: '#A8FF53',

    cards: [
      { ...tutoringCard, price: 10, priceText: '$', newPrice: null },
      { ...externalCard, price: 37, priceText: '$/мес', newPrice: null },
      { ...plusCard, price: 40, priceText: '$/мес', newPrice: null },
      { ...careerGuidanceCard, price: 10, priceText: '$', newPrice: null },
      { ...recordLessonCard, price: 0, priceText: '$', newPrice: null },
    ],
  },
  {
    color: '#B4DF80',
    btnColor: '#A8FF53',
    girlImage: true,
    girlBtnColor: '#FF7233',

    cards: [
      { ...tutoringCard, price: 10, priceText: '$', newPrice: null },
      { ...externalCard, price: 37, priceText: '$/мес', newPrice: null },
      { ...ogeCard, price: 40, priceText: '$/мес', newPrice: null },
      { ...careerGuidanceCard, price: 10, priceText: '$', newPrice: null },
      { ...plusCard, price: 40, priceText: '$/мес', newPrice: null },
    ],
  },
  {
    color: '#9FE3FF',
    btnColor: '#41E8FF',
    girlImage: true,
    girlBtnColor: '#C972FF',

    cards: [
      { ...tutoringCard, price: 10, priceText: '$', newPrice: null },
      { ...externalCard, price: 55, priceText: '$/мес', newPrice: null },
      { ...egeCard, price: 40, priceText: '$/мес', newPrice: null },
      { ...careerGuidanceCard, price: 10, priceText: '$', newPrice: null },
      { ...plusCard, price: 40, priceText: '$/мес', newPrice: null },
    ],
  },
];

const schoolCenyCards = [
  {
    title: 'Курсы ЕГЭ',
    description: 'Подготовим к ЕГЭ 2025 на максимум. Решаем реальные варианты экзаменов, даем лайфхаки решений и формулы.',
    labels: ['2 раза в неделю'],
    cardButtonFunc: 'toggleLead2Modal',
    installmentPriceText: 'для учеников школы',
    price: 0,
    leadTitle: 'Бесплатная консультация по курсам ЕГЭ',
  },
  {
    title: 'Курсы ОГЭ',
    description: 'Подготовим к ОГЭ 2025 на 4 и 5. Решаем реальные варианты экзаменов, даем лайфхаки решений и формулы.',
    labels: ['2 раза в неделю'],
    cardButtonFunc: 'toggleLead2Modal',
    installmentPriceText: 'для учеников школы',
    price: 0,
    leadTitle: 'Бесплатная консультация по курсам ОГЭ',
  },
  softSkillsCard,
  programmingCard,
  {
    ...tutoringCard,
    link: '/catalog/repetitor',
    leadTitle: 'Консультация по репетиторству',
  },
  {
    ...careerGuidanceCard,
    newPrice: null,
    price: 1500,
    cardButtonFunc: 'toggleLead2Modal',
    leadTitle: 'Консультация по профориентации',
  },
  schoolPsyhologist,
  dzCard,
  languageCourses,
  collegeCard,
  universityCard,
  secondDiploma,
];
export const schoolCenyPackages = [
  {
    color: '#FBD277',
    btnColor: '#FF7233',
    cards: schoolCenyCards,
  },
  {
    color: '#B4DF80',
    btnColor: '#A8FF53',
    cards: schoolCenyCards,
  },
  {
    color: '#9FE3FF',
    btnColor: '#41E8FF',
    cards: schoolCenyCards,
  },
];

export const schoolClassPackages = [
  {
    color: '#FBD277',
    btnColor: '#FF7233',
    cards: [
      careerGuidanceCard,
      dzCard,
      tutoringCard,
      schoolPsyhologist,
      languageCourses,
      softSkillsCard,
      programmingCard,
    ],
  },
  {
    color: '#B4DF80',
    btnColor: '#A8FF53',
    cards: [
      careerGuidanceCard,
      ogeCard,
      tutoringCard,
      schoolPsyhologist,
      collegeCard,
      dzCard,
      programmingCard,
      softSkillsCard,
      languageCourses,
    ],
  },
  {
    color: '#9FE3FF',
    btnColor: '#41E8FF',
    cards: [
      careerGuidanceCard,
      egeCard,
      tutoringCard,
      schoolPsyhologist,
      universityCard,
      secondDiploma,
      programmingCard,
      languageCourses,
      softSkillsCard,
      dzCard,
    ],
  },
];

export const tutorsLessons = [
  {
    age: '',
    color: '#B4DF80',
    btnColor: '#A8FF53',

    cards: [
      {
        subtitle: '«Взлет»',
        title: '4 урока',
        description: 'Для тех, кому необходимо быстро разобраться в&nbsp;конкретной теме или подготовиться к&nbsp;экзамену. Вы&nbsp;сможете сосредоточиться на&nbsp;самых важных аспектах предмета и&nbsp;получить нужные знания',
        labels: ['1 500 ₽/урок', '2 недели', '2 раза/нед'],
        price: 6000,
        // newPrice: 5100,
        priceText: '₽',
        dropdownText: true,
        priceFromText: null,
      },
      {
        subtitle: '«Будь в форме»',
        title: '8 уроков',
        description: 'В&nbsp;случае, когда необходимо более углубленное изучение темы или подготовка к&nbsp;сложному экзамену. Дополнительные занятиям помогут углубленно изучить предмет, отработать навыки и&nbsp;устранить пробелы в&nbsp;знаниях',
        labels: ['1 400 ₽/урок', '1 месяц', '2 раза/нед'],
        price: 11200,
        // newPrice: 9520,
        priceText: '₽',
        dropdownText: true,
        priceFromText: null,
      },
      {
        subtitle: '«Прогрессируй»',
        title: '16 уроков',
        description: 'Для освоения сложных тем и&nbsp;удержания высоких показателей в&nbsp;учебе. Этот пакет также подойдет для начальной подготовки к&nbsp;государственным экзаменам или другим серьезным испытаниям',
        labels: ['1 300 ₽/урок', '2 месяц', '2 раза/нед'],
        price: 20800,
        // newPrice: 17680,
        priceText: '₽',
        dropdownText: true,
        priceFromText: null,
      },
      {
        subtitle: '«Будь в топе»',
        title: '32 урока',
        description: 'Если вам необходимо обширное покрытие предмета и&nbsp;подготовка на&nbsp;высоком уровне. Вы&nbsp;сможете углубиться в&nbsp;темы, отработать навыки и&nbsp;получить полное понимание предмета',
        labels: ['1 200 ₽/урок', '4 месяца', '2 раза/нед'],
        price: 38400,
        // newPrice: 32640,
        priceText: '₽',
        stickerUrl: '/img/hit.svg',
        dropdownText: true,
        priceFromText: null,
      },
      {
        subtitle: '«Покоряй вершины»',
        title: '64 урока',
        description: 'Для тех, кто стремится к&nbsp;максимальному владению предметом и&nbsp;достижению выдающихся результатов. С&nbsp;поддержкой нашего профессионального репетитора сможете стать настоящим экспертом в&nbsp;выбранной области',
        labels: ['1 100 ₽/урок', '8 месяцев', '2 раза/нед'],
        price: 70400,
        // newPrice: 59840,
        priceText: '₽',
        dropdownText: true,
        priceFromText: null,
      },
      {
        subtitle: '«Обрети силу»',
        title: '96 уроков',
        description: 'Для тех, кто ищет полное и&nbsp;исчерпывающее обучение. Вы&nbsp;сможете стать экспертом в&nbsp;предмете и&nbsp;успешно применять полученные знания в&nbsp;практических ситуациях',
        labels: ['1 000 ₽/урок', '12 месяцев', '2 раза/нед'],
        price: 96000,
        // newPrice: 81600,
        priceText: '₽',
        stickerUrl: '/img/top.svg',
        dropdownText: true,
        priceFromText: null,
      },
    ],
  },
];

export const tutorsOther = [
  {
    age: '',
    color: '#FBD277',
    btnColor: '#FF7233',
    girlImage: true,
    girlBtnColor: '#A8FF53',
    accentTextColor: '#FF7233',
    colorClassName: 'orange',

    cards: [
      { ...schoolLiteCard },
      { ...schoolComfortCard },
      { ...schoolPremiumCard, stickerUrl: '/v2/global/black-friday/top-sale-violet.svg' },
      // { ...careerGuidanceCard, price: 1500, newPrice: null },
      { ...proforientaciaCard },
      {
        title: 'Курсы ЕГЭ и ОГЭ',
        description: 'Подготовим к&nbsp;ЕГЭ/ОГЭ 2025&nbsp;на максимум. Решаем реальные варианты экзаменов, даем лайфхаки решений и&nbsp;формулы',
        labels: ['по всем предметам', 'вебинары', '1 и 2 части'],
        price: '0',
        priceText: '₽',
        priceFromText: 'первый урок',
        installmentPriceText: 'по любому предмету',
        link: '/ege',
      },
    ],
  },
];

export const egeData = [
  {
    age: '',
    color: '#B4DF80',
    btnColor: '#A8FF53',
    girlImage: true,
    girlBtnColor: '#FF7233',

    cards: [
      { ...schoolLiteCard },
      { ...schoolComfortCard },
      { ...schoolPremiumCard, stickerUrl: '/v2/global/black-friday/top-sale-blue.svg' }, // stickerUrl: '/v2/external/sticker-top.svg'
      { ...tutoringCard },
      { ...recordLessonCard, stickerUrl: '/v2/global/black-friday/top-free.svg' },
    ],
  },
];

export const ogeData = [
  {
    age: '',
    color: '#B4DF80',
    btnColor: '#A8FF53',
    girlImage: true,
    girlBtnColor: '#FF7233',

    cards: [
      { ...schoolLiteCard },
      { ...schoolComfortCard },
      { ...schoolPremiumCard, stickerUrl: '/v2/global/black-friday/top-sale-blue.svg' }, // stickerUrl: '/v2/external/sticker-top.svg'
      { ...tutoringCard },
      { ...recordLessonCard, stickerUrl: '/v2/global/black-friday/top-free.svg' },
    ],
  },
];

export const externalData = [
  {
    age: '',
    color: '#B4DF80',
    btnColor: '#A8FF53',
    girlImage: true,
    girlBtnColor: '#FF7233',
    accentTextColor: '#B4DF80',

    cards: [
      { ...tutoringCard, link: '/catalog/repetitor' },
      // { ...schoolLiteCard },
      { ...schoolComfortCard },
      { ...schoolPremiumCard, stickerUrl: '/v2/global/black-friday/top-sale-blue.svg' }, // stickerUrl: '/v2/external/sticker-top.svg',
      // { ...tutoringCard },
      { ...proforientaciaCard },
      { ...recordLessonCard, stickerUrl: '/v2/global/black-friday/top-free.svg' },
    ],
  },
];

export default {
  mainData,
  schoolPackages,
  schoolComPackages,
  schoolCenyPackages,
  schoolClassPackages,
  tutorsLessons,
  tutorsOther,
  egeData,
  ogeData,
  externalData,
};
