<template>
  <section class="school-education-forms v2-container">
    <h3 v-if="semeinoePage">
      Подробнее о формах обучения
    </h3>
    <h3 v-else>
      какие бывают Формы обучения{{ textAdded }}?
    </h3>

    <div class="school-education-forms__content">
      <div class="school-education-forms__left">
        <!-- eslint-disable max-len, vue/max-len -->
        <p>
          Смотрите видео с&nbsp;директором онлайн‑школы &laquo;Синергия&raquo; о&nbsp;плюсах
          и&nbsp;минусах семейной и&nbsp;заочной формы обучения{{ textAdded }}, а&nbsp;так&nbsp;же скачивайте
          <br class="tablet">
          «<span class="no-tablet">Чек-лист по&nbsp;переходу в&nbsp;онлайн‑школу</span><span class="tablet">Пошаговый план перехода в&nbsp;онлайн‑школу</span>»
        </p>
        <!-- eslint-enable -->
        <button
          class="m-btn"
          @click="$store.dispatch('modals/toggleLead11')"
        >
          Скачать план
        </button>
      </div>
      <button
        class="school-education-forms__right m-btn"
        @click="openVideoModal('/v2/school/edu-forms/Онлайн школа 29 05.mp4')"
      >
        <img
          src="/v2/school/edu-forms/card.webp"
          alt="формы обучения в онлайн-школе"
          class="school-education-forms__right_card"
        >
        <img
          src="/v2/school/edu-forms/play.svg"
          alt="play"
          class="school-education-forms__right_play"
        >
      </button>
    </div>
  </section>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'SchoolEducationForms',
  computed: {
    semeinoePage() {
      return this.$route.name === 'online-school-semeinoe-obuchenie';
    },
    classPage() {
      return Boolean(this.$route.name.match(/online-school-[0-9]{1,2}-klass/));
    },
    textAdded() {
      if (this.classPage) {
        const classNumber = this.$route.name.match(/[0-9]{1,2}/)?.[0] || 8;
        return ` в ${classNumber} классе`;
      }

      return '';
    },
  },
  methods: {
    ...mapActions('modals', {
      openVideoModal: 'openVideo',
    }),
  },
};
</script>

<style scoped lang="scss">

.school-education-forms {
  h3 {
    margin-bottom: 60px;

    font-weight: 700;
    font-size: 44px;
    line-height: 50px;
    letter-spacing: -1.35px;
    text-transform: uppercase;
    text-align: center;
    font-feature-settings: 'case' on;
    color: $black;
  }

  .school-education-forms__content {
    display: flex;
    gap: 40px;
    height: 420px;

    @include media-down(desktop) {
      gap: 20px;
    }

    @include media-down(laptop) {
      flex-direction: column;
      height: auto;
    }
  }

  .school-education-forms__left {
    flex-basis: 533px;
    padding: 33px 38px 38px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    background-color: $white-color;
    border: 2px solid $black;
    box-shadow: 4px 4px 0 $black;
    border-radius: 20px;

    @include media-down(desktop) {
      padding-left: 15px;
      padding-right: 15px;
    }

    @include media-down(laptop) {
      padding: 14px 14px 8px;
      flex-basis: 260px;
      box-shadow: 2px 4px 0 $black;
      border-radius: 16px;
    }

    p {
      font-weight: 500;
      font-size: 24px;
      line-height: 130%;
      font-feature-settings: 'pnum' on, 'lnum' on;
      color: $black;

      @include media-down(tablet) {
        font-size: 18px;
        line-height: 120%;
      }

      span {
        color: #FA5019;
      }
    }

    button {
      display: block;
      width: 100%;
      padding: 22px 44px;

      background-color: $header-orange;
      border: 2px solid $black;
      border-radius: 200px;
      transition: 200ms;

      font-weight: 700;
      font-size: 30px;
      line-height: 100%;
      color: $black;

      @include media-down(tablet) {
        padding: 15px 10px 21px;
        box-shadow: 2px 4px 0 $black;

        font-size: 25px;
      }

      @media (hover: hover) {
        &:hover {
          box-shadow: 4px 4px 0 $black;
          transform: translate(-4px, -4px);
        }
      }
    }
  }

  .school-education-forms__right {
    position: relative;
    border: 2px solid $black;
    box-shadow: 4px 4px 0 $black;
    border-radius: 20px;
    overflow: hidden;
    line-height: 0;

    @include media-down(laptop) {
      box-shadow: 2px 4px 0 $black;
      filter: none;
      border-radius: 16px;
    }

    .school-education-forms__right_card {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .school-education-forms__right_play {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

</style>
