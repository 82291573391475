<template>
  <div class="packages">
    <div
      id="packages"
      class="packages__anchor"
      aria-hidden="true"
    />

    <div class="packages__m-container v2-container">
      <h2
        v-if="title"
        class="packages__title title-secondary"
        v-html="title"
      />

      <div class="packages__tabs tabs">
        <div
          v-if="!hideTabs"
          class="tabs__head"
        >
          <button
            v-for="(item, index) in packagesList"
            :key="index"
            :class="['m-btn', 'tabs__btn', {
              'tabs__btn--active': index === activeTab,
            }]"
            :style="index === activeTab ? { backgroundColor: item.color } : {}"
            @click="activeTab = index; activeCards = []"
          >
            <span class="tabs__age title-secondary">{{ item.age }}</span>
            класс
          </button>
        </div>

        <div
          v-if="packagesList[activeTab]"
          class="tabs__body"
          :style="{ backgroundColor: currentList.color }"
        >
          <div
            v-for="(card, cIndex) in packagesList[activeTab].cards"
            :key="cIndex"
            class="tabs__card card"
          >
            <div class="card__inner">
              <h6
                v-if="card.subtitle"
                class="card__subtitle"
              >
                {{ card.subtitle }}
              </h6>
              <h3
                class="card__title title-tertiary"
                @click="toggleDrowdown(cIndex)"
              >
                {{ card.title }}

                <img
                  loading="lazy"
                  class="card__arrow"
                  :class="{ 'card__arrow--active' : activeCards.includes(cIndex) }"
                  src="/v2/main/packages/arrow.svg"
                  alt="arrow"
                >
              </h3>

              <p
                :class="['card__descr', 'text-primary', currentList.colorClassName, {
                  'card__descr--active': isShowDescField(card, cIndex),
                }]"
                v-html="card.description"
              />

              <div
                :class="['card__spoiler', { 'card__spoiler--active': isShowField(card, cIndex) }]"
              >
                <!--
                :style="{
                  opacity: isShowField(card, cIndex) ? 1 : 0,
                  maxHeight: isShowField(card, cIndex) ? '500px' : 0,
                }"
                -->
                <ul
                  v-if="card.labels"
                  class="card__labels card__labels--top"
                >
                  <li
                    v-for="(label, lIndex) in card.labels"
                    :key="lIndex"
                    class="card__label"
                  >
                    {{ label }}
                  </li>
                </ul>

                <div
                  v-if="card.discount"
                  class="card__discount"
                >
                  <p class="card__tx text-primary">
                    Больше занятий - больше скидка
                  </p>

                  <ul class="card__labels card__labels--bottom">
                    <li
                      v-for="(disc, dIndex) in card.discount"
                      :key="dIndex"
                      class="card__label card__label--bordered"
                    >
                      {{ disc }}
                    </li>
                  </ul>
                </div>

                <ul
                  v-if="card.list"
                  class="card__list"
                >
                  <li
                    v-for="(item, lIndex) in card.list"
                    :key="lIndex"
                    class="card__list_item"
                    v-html="item"
                  />
                </ul>
              </div>

              <div class="card__bottom">
                <div class="card__price price">
                  <p
                    v-if="card.price && card.newPrice"
                    class="price__from price__tx-small price__from--strike"
                  >
                    от
                    <s>
                      <span>
                        {{ card.price | prettyCurrency(false) }}
                      </span>
                      {{ card.priceText || '₽/мес' }}
                    </s>
                  </p>
                  <span
                    v-else-if="card.price && card.priceFromText !== null"
                    :class="['price__from price__tx-small']"
                  >
                    {{ card.priceFromText || 'от' }}
                  </span>

                  <div
                    v-if="card.price"
                    class="price__row"
                  >
                    <span class="price__num">
                      {{ (card.newPrice || card.price) | prettyCurrency(false) }}
                    </span>
                    <span class="price__tx-small">{{ card.priceText || '₽/мес' }}</span>
                  </div>
                  <p
                    v-else-if="'price' in card"
                    class="price__row price__row--free"
                    :style="{ color: card.withAccentColor ? currentList.accentTextColor : '' }"
                  >
                    Бесплатно
                  </p>

                  <p
                    v-if="card.installmentPriceText"
                    class="price__installment"
                  >
                    {{ card.installmentPriceText }}
                  </p>
                </div>

                <nuxt-link
                  v-if="card.link"
                  :to="card.link"
                  class="card__btn m-btn m-btn--secondary"
                >
                  <span
                    class="m-btn__content m-btn__content--secondary"
                    :style="{ backgroundColor: currentList.btnColor }"
                  >
                    Подробнее
                  </span>
                </nuxt-link>

                <button
                  v-else
                  class="card__btn m-btn m-btn--secondary"
                  @click="card.cardButtonFunc ? runCardFunc(card) : toggleLead2Modal()"
                >
                  <span
                    class="m-btn__content m-btn__content--secondary"
                    :style="{ backgroundColor: currentList.btnColor }"
                  >
                    {{ card.buttonText || 'Подробнее' }}
                  </span>
                </button>
              </div>
            </div>

            <img
              v-if="card.sticker || card.stickerUrl"
              loading="lazy"
              class="card__sticker"
              :src="card.stickerUrl || '/v2/main/packages/sticker-' + activeTab +'.svg'"
              alt="sticker"
            >
          </div>

          <template v-if="currentList.girlImage">
            <div class="tabs__request request">
              <h3 class="request__title">
                Не знаете, какой именно вариант вам подходит?
              </h3>

              <p class="request__tx">
                Отдел заботы поможет разобраться
              </p>

              <button
                class="request__btn m-btn m-btn--primary"
                @click="toggleLead2Modal"
              >
                <span
                  class="request__btn-content m-btn__content m-btn__content--primary"
                  :style="{ backgroundColor: currentList.girlBtnColor }"
                >
                  Оставить заявку
                </span>
              </button>
            </div>

            <img
              loading="lazy"
              class="tabs__img-girl"
              src="/v2/main/packages/girl.webp"
              alt="girl"
            >
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import PackagesData from './PackagesData';

export default {
  name: 'PackagesList',

  props: {
    dataVariable: {
      type: String,
      required: true,
    },
    defaultActive: {
      type: Number,
      default: 0,
    },
    hideTabs: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
  },

  data: () => ({
    activeTab: 0,
    activeCards: [],
  }),

  computed: {
    ...mapGetters({
      windowSize: 'getWindowSize',
      isTablet: 'isTablet',
    }),
    packagesList() {
      return PackagesData[this.dataVariable] || [];
    },
    currentList() {
      return this.packagesList[this.activeTab];
    },
  },

  watch: {
    defaultActive: {
      immediate: true,
      handler(val) {
        this.activeTab = val;
      },
    },
    // activeTab() {
    //   this.getCardsFieldsHeight();
    // },
  },

  mounted() {
    // setTimeout(() => {
    //   this.getCardsFieldsHeight();
    // }, 1000);
  },

  methods: {
    ...mapActions('modals', {
      toggleLead1Modal: 'toggleLead1',
      toggleLead2Modal: 'toggleLead2',
    }),
    toggleDrowdown(id) {
      if (this.activeCards.includes(id)) {
        this.activeCards = this.activeCards.filter((item) => item !== id);
      } else {
        this.activeCards.push(id);
      }
    },
    runCardFunc({ cardButtonFunc, leadTitle }) {
      this[cardButtonFunc](leadTitle);
    },

    isShowDescField(card, index) {
      return this.isTablet && card.dropdownText ? this.activeCards.includes(index) : true;
    },
    isShowField(card, index) {
      return this.isTablet && !card.dropdownText ? this.activeCards.includes(index) : true;
    },
    // getHeight(className) {
    //   // ждем отрисовки DOM
    //   if (process.browser) {
    //     const scrollHeight = document.querySelector(`.${className}`)?.scrollHeight || 0;
    //     return `${scrollHeight}px`;
    //   }
    //
    //   return 0;
    // },

    // getCardsFieldsHeight() {
    //   this.packagesList[this.activeTab].cards.forEach((card, index) => {
    //     // console.log(this.getHeight(`card__descr--${index}`));
    //     // console.log(this.getHeight(`card__spoiler--${index}`));
    //     // eslint-disable-next-line no-param-reassign
    //     card.descHeight = this.getHeight(`card__descr--${index}`);
    //     // eslint-disable-next-line no-param-reassign
    //     card.spoilerHeight = this.getHeight(`card__spoiler--${index}`);
    //     console.log(index, this.getHeight(`card__spoiler--${index}`));
    //   });
    // },
  },
};
</script>

<style lang="scss" scoped>
.packages {
  position: relative;

  &__anchor {
    position: absolute;
    top: -20px;
  }

  .card__subtitle {
    color: $black;
    font-size: 20px;
    font-weight: 700;

    @include media-down(tablet) {
      font-size: 16px;

      margin-bottom: 16px;

      & + .card__title {
        order: -1;
        margin-bottom: 0;
      }
    }
  }

  &__title {
    position: relative;
    max-width: 730px;
    margin: 0 auto 60px;

    text-align: center;

    z-index: 4;

    @include media-down(tablet) {
      margin-bottom: 40px;
    }
  }

  &__sticker {
    position: absolute;
    right: -16.2%;
    top: -5.2%;

    z-index: 3;

    @media (max-width: 1650px) {
      right: -120px;
    }

    @include media-down(desktop) {
      right: -20px;
      top: -7.5%;
    }

    @include media-down(laptop) {
      top: 4%;
    }

    @include media-down(tablet) {
      top: -5.5%;
      right: -25px;
    }

    &-image {
      max-width: 407px;
      transform: rotate(16.734deg);

      @include media-down(desktop) {
        max-width: 307px;
      }

      @include media-down(laptop) {
        max-width: 247px;
      }

      @include media-down(tablet) {
        max-width: 190px;
        transform: rotate(8.729deg);
      }
    }
  }
  &__sticker.externat {
    top: -6.5%;

    @include media-down(desktop) {
      top: -7.5%;
    }

    @include media-down(laptop) {
      top: 4%;
    }

    @include media-down(tablet) {
      top: -5.5%;
    }
  }
}

.tabs {
  &__head {
    display: flex;
    justify-content: center;

    margin: 0 auto 20px;

    min-width: 960px;
    width: calc(100% - 80px);

    border-bottom: 2px solid $black;

    @include media-down(laptop) {
      gap: 8px;

      margin: 0 -16px 16px;
      padding: 2px 16px;

      min-width: auto;
      width: calc(100vw - 17px);

      border: none;

      overflow: auto;
    }

    @include media-down(tablet) {
      justify-content: flex-start;
      width: 100vw;
    }
  }

  &__btn {
    position: relative;
    z-index: 1;
    color: inherit;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;

    width: 320px;
    height: 120px;

    border: 2px dashed $black;
    border-bottom: 2px solid transparent;
    border-right: 2px solid transparent;
    border-radius: 20px 20px 0 0;

    font-size: 35px;
    font-weight: 500;
    line-height: 28px;

    transition: 0.2s ease-out;

    @include media-down(laptop) {
      width: 120px;
      height: 88px;

      border-bottom: 2px dashed $black;
      border-right: 2px dashed $black;
      border-radius: 16px;

      font-size: 18px;
      line-height: 18px;
    }

    &:not(.tabs__btn--fird):not(:hover):last-child {
      border-right: 2px dashed $black;
    }

    @media (hover: hover) {
      &:hover {
        border: 2px solid transparent;

        box-shadow: 0 0 0 2px $black;
      }
    }

    &--active {
      z-index: 2;
      border: 2px solid transparent;
      box-shadow: 0 0 0 2px $black;
    }
  }

  &__body {
    position: relative;

    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    gap: 30px;

    padding: 40px;

    border-radius: 20px;
    border: 2px solid $black;

    overflow: hidden;

    box-shadow: 4px 4px 0 0 $black;

    @include media-down(laptop) {
      gap: 12px;

      padding: 12px;
    }

    @include media-down(tablet) {
      grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
      gap: 8px;

      padding: 8px;
    }
  }

  &__img-girl {
    position: absolute;
    bottom: 0;
    right: 0;

    width: 328px;

    @include media-down(tablet) {
      bottom: -28px;

      width: 193px;
    }
  }
}

.card {
  position: relative;

  padding: 30px 25px;

  min-height: 500px;

  border-radius: 15px;
  border: 2px solid $black;

  background: $white-color;

  @include media-down(tablet) {
    padding: 12px 16px;

    min-height: 220px;
  }

  &__inner {
    position: relative;
    z-index: 1;

    display: flex;
    flex-direction: column;

    height: 100%;
  }

  &__title {
    display: flex;
    gap: 8px;

    margin-bottom: 24px;

    letter-spacing: -0.8px;

    @include media-down(tablet) {
      margin-bottom: 8px;

      font-size: 20px;
      line-height: 26px;
    }
  }

  &__arrow {
    display: none;

    transform: rotate(180deg);

    transition: 0.2s ease-out;

    @include media-down(tablet) {
      display: block;
    }

    &--active {
      transform: rotate(0deg);
    }
  }

  &__descr {
    display: block;
    transition: 200ms;

    @include media-down(tablet) {
      font-size: 18px;
      line-height: 21px;
    }

    @include media-down(tablet) {
      opacity: 0;
      max-height: 0;
      transition: 0.25s linear;
    }

    &--active {
      margin-bottom: 16px;

      @include media-down(tablet) {
        opacity: 1;
        max-height: 500px;
        transition: 0.4s linear;
        margin-bottom: 24px;
      }
    }
  }
  &__descr.orange {
    ::v-deep span {
      color: #FF7233;
    }
  }
  &__descr.blue {
    ::v-deep span {
      color: #9FE3FF;
    }
  }
  &__descr.green {
    ::v-deep span {
      color: #B4DF80;
    }
  }

  &__spoiler {
    margin-bottom: 20px;

    overflow: hidden;
    transition: 200ms;

    @include media-down(tablet) {
      opacity: 0;
      max-height: 0;
      transition: 0.25s ease-out;
    }

    &--active {
      @include media-down(tablet) {
        opacity: 1;
        max-height: 500px;
        transition: 0.5s ease-in;
      }
    }
  }

  &__labels {
    display: flex;
    gap: 4px 2px;
    flex-wrap: wrap;

    &--top {
      margin-bottom: 20px;
    }
  }

  &__label {
    padding: 10px 15px;

    width: fit-content;

    border-radius: 50px;

    background-color: #e8e9eb;

    font-size: 16px;
    font-weight: 700;

    @include media-down(tablet) {
      padding: 8px 12px 12px;

      font-size: 14px;
    }

    &--bordered {
      border: 2px solid $black;

      background-color: $white-color;

      @include media-down(tablet) {
        padding: 7px 12px 9px;
      }
    }
  }

  .card__list {
    display: flex;
    flex-direction: column;
    gap: 15px;

    .card__list_item {
      font-variant-numeric: lining-nums proportional-nums;
      font-size: 20px;
      font-weight: 500;
      line-height: 130%;
      letter-spacing: -0.2px;
      padding-left: 11px;
      position: relative;

      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 6px;
        height: 100%;

        background-image: url("/v2/school/way/li-star.svg");
        background-repeat: no-repeat;
        background-size: contain;
      }
    }
  }

  &__tx {
    margin-bottom: 10px;

    @include media-down(tablet) {
      margin-bottom: 16px;

      font-size: 18px;
      line-height: 21px;
    }
  }

  &__bottom {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    margin-top: auto;
  }

  &__btn {
    color: $black;
    text-align: center;

    @include media-down(tablet) {
      display: flex;
      align-items: center;
      justify-content: center;

      padding: 0;

      width: 133px;
      height: 44px;
    }

    .m-btn__content {
      padding: 10px 18.5px;

      @include media-down(tablet) {
        padding: 10px 13.5px;
      }
    }
  }

  &__sticker {
    position: absolute;
    top: 0;
    right: 0;

    border-radius: 13px;

    pointer-events: none;
  }
}

.price {
  margin-bottom: 5px;

  @include media-down(tablet) {
    margin-bottom: 0;
  }

  &__tx-small {
    color: $grey2;
    font-size: 20px;
    font-weight: 500;

    @include media-down(laptop) {
      font-size: 16px;
    }
  }

  .price__from--strike {
    color: $grey2;

    span {
      font-weight: 600;
    }
  }

  &__row {
    display: flex;
    align-items: baseline;
    gap: 2px;

    white-space: nowrap;
  }

  .price__row--free {
    color: $black-color;
    font-feature-settings: 'case' on;
    font-size: 25px;
    font-weight: 700;
    line-height: 44px;
    letter-spacing: -0.5px;
    text-transform: uppercase;
  }

  &__num {
    font-size: 45px;
    font-weight: 700;
    letter-spacing: -0.9px;

    white-space: nowrap;

    @include media-down(laptop) {
      font-size: 35px;
    }
  }

  &__installment {
    position: absolute;
    font-size: 20px;
    font-weight: 500;
    line-height: 90%;
    letter-spacing: -0.4px;
    color: $grey2;

    @include media-down(tablet) {
      position: relative;
      font-size: 16px;
      letter-spacing: -0.32px;
    }
  }
}

.request {
  position: relative;
  z-index: 1;

  @include media-down(tablet) {
    padding: 11px 13px 16px;
  }

  &__title {
    margin-bottom: 20px;

    max-width: 300px;

    font-size: 40px;
    font-weight: 700;
    line-height: 40px;
    letter-spacing: -0.8px;
    color: $black;

    @include media-down(tablet) {
      margin-bottom: 8px;

      font-size: 20px;
      line-height: 20px;
      letter-spacing: normal;
    }
  }

  &__tx {
    margin-bottom: 20px;

    max-width: 197px;

    font-size: 20px;
    font-weight: 500;
    line-height: 26px;
    letter-spacing: -0.2px;
    color: $black;

    @include media-down(tablet) {
      margin-bottom: 56px;

      font-size: 18px;
      line-height: 21px;
    }
  }

  &__btn {
    width: 192px;
    height: 44px;

    border-radius: 50px;

    @include media-down(tablet) {
      width: 177px;
    }
  }

  &__btn-content {
    padding: 7px 2px 13px 2px !important;

    width: 192px;
    height: 44px;

    border-radius: 50px;

    font-size: 20px !important;
    font-weight: 700 !important;

    @include media-down(tablet) {
      width: 177px;

      font-size: 18px !important;
    }
  }
}
</style>
