<template>
  <div class="sw-modal">
    <div class="sw-modal__inner">
      <button
        class="sw-modal__close m-btn"
        @click="$emit('close')"
      >
        <img
          src="/v2/modal/close.svg"
          alt="cross"
          class="sw-modal__close-icon"
        >
      </button>

      <h2
        class="sw-modal__title"
        v-html="modalTitle"
      />

      <h5 class="sw-modal__subtitle">
        Наш специалист свяжется с вами
        <br>
        и ответит на любые вопросы
      </h5>

      <div class="sw-modal__fields">
        <form-name-field
          ref="nameField"
          v-model="nameInput"
          class="form__input"
          placeholder="Имя"
        />

        <form-phone-field
          ref="phoneField"
          class="form__input form__input_phone"
          placeholder="Телефон родителя"
          :errors-list="submitError"
          :error="validForm === false"
          @updatePhone="phoneInput = $event"
        />

        <form-phone-field
          ref="phoneCommentField"
          class="form__input form__input_phone"
          placeholder="Телефон ученика"
          :errors-list="submitError"
          :error="validForm === false"
          @updatePhone="phoneCommentInput = $event"
          @clearSubmitError="clearSubmitError"
        />

        <form-email-field
          ref="emailField"
          v-model="emailInput"
          class="form__input form__input_email"
          :submit-error="submitError"
          placeholder="Почта"
          @clearSubmitError="clearSubmitError"
        />
      </div>

      <div class="sw-modal__submit">
        <button
          class="m-btn"
          @click="submit"
        >
          Отправить
        </button>
      </div>

      <p class="sw-modal__privacy">
        Нажимая на кнопку, вы соглашаетесь
        <nuxt-link
          to="/privacy"
          target="_blank"
        >
          с условиями обработки данных
        </nuxt-link>
      </p>

      <div class="sw-modal__social">
        <p class="sw-modal__social--text">
          Или напишите нам в чат:
        </p>

        <div class="contacts__row-bottom">
          <a
            class="contacts__link"
            href="https://wa.me/79686286327"
            target="_blank"
            rel="nofollow"
          >
            <img
              class="contacts__soc-icon"
              src="/v2/modal/whatsapp.svg"
              alt="whatsapp"
            >
          </a>

          <a
            class="contacts__link"
            :href="$getTelegramLink($route)"
            target="_blank"
            rel="nofollow"
          >
            <img
              class="contacts__soc-icon"
              src="/v2/modal/telegram.svg"
              alt="telegram"
            >
          </a>

          <a
            class="contacts__link"
            href="mailto:nstolbova@synergy.ru"
            target="_blank"
            rel="nofollow"
          >
            <img
              class="contacts__soc-icon"
              src="/v2/modal/email.svg"
              alt="email"
            >
          </a>
        </div>
      </div>
    </div>

    <div
      class="sw-modal__overlay"
      @click="$emit('close')"
    />
  </div>
</template>

<script>
import FormNameField from '@/components/v2/common/fileds/FormNameField.vue';
import FormEmailField from '@/components/v2/common/fileds/FormEmailField.vue';
import FormPhoneField from '@/components/v2/common/fileds/FormPhoneField.vue';

import { getQuestionnairePath } from '@/utils/questionnairePath';
import sendLeadMixinConstructor from '@/mixins/sendLeadMixinConstructor';

const sendLeadMixin = sendLeadMixinConstructor({
  needPhone: true,
  needEmail: true,
  needToSendTitle: true,
  needToSendComments: true,
  needCommentPhone: true,
});

export default {
  name: 'SchoolWayModal',
  components: {
    FormPhoneField,
    FormEmailField,
    FormNameField,
  },
  mixins: [sendLeadMixin],
  props: {
    modalType: {
      type: String,
      default: '',
    },
  },

  computed: {
    modalTitle() {
      switch (this.modalType) {
        case 'external':
          return '<span><span>Бесплатная</span></span> консультация по тарифу “ЭКСТЕРНАТ”';
        case 'comfort':
          return '<span><span>Бесплатная</span></span> консультация по тарифу “КОМФОРТ”';
        case 'premium':
          return '<span><span>Бесплатная</span></span> консультация по тарифу “ПРЕМИУМ”';
        default:
          return '';
      }
    },
    titleToSend() {
      return this.modalTitle.replace(/<\/?[^>]+(>|$)/g, '');
    },
  },

  methods: {
    onSubmit() {
      this.$router.push(getQuestionnairePath(this.$route));
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
.sw-modal {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  .sw-modal__inner {
    position: relative;
    z-index: 2;

    margin: auto;

    width: min(610px, calc(100% - 16px));
    height: fit-content;
    max-height: 95vh;
    padding: 40px 40px 38px;

    border-radius: 20px;
    border: 2px solid $black;
    background: $white-color;
    box-shadow: 4px 4px 0 0 $black;

    overflow-x: hidden;
    overflow-y: auto;

    &::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 3px;
    }

    &::-webkit-scrollbar-track {
      width: 3px !important;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 3px !important;
      width: 3px;
      background-color: #8d8d8d !important;
    }

    @include media-down(tablet) {
      padding: 16px 16px 40px;
      box-shadow: 2px 4px 0 0 $black;
    }

    @include media-down(mobile) {
      overflow: hidden;
    }
  }

  .sw-modal__close {
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
  }

  ::v-deep .sw-modal__title {
    padding: 0 20px;

    color: $black;
    text-align: center;
    font-size: 40px;
    font-weight: 700;
    line-height: 100%;
    letter-spacing: -1.2px;

    @include media-down(tablet) {
      max-width: 312px;
      padding: 0;
      margin: 0 auto;

      color: $black;
      text-align: center;
      font-size: 24px;
      font-weight: 700;
      line-height: 100%;
    }

    & > span {
      position: relative;

      &:before {
        content: '';
        width: 100%;
        height: 36px;
        background-color: #FFB379;
        position: absolute;
        left: 0;
        top: 6px;

        @include media-down(tablet) {
          height: 22px;
          top: 4px;
        }
      }

      span {
        position: relative;
        z-index: 2;
      }
    }
  }

  .sw-modal__subtitle {
    max-width: 414px;
    margin: 10px auto 0;

    color: $black;
    text-align: center;
    font-variant-numeric: lining-nums proportional-nums;
    font-size: 24px;
    font-weight: 500;
    line-height: 130%;

    @include media-down(tablet) {
      margin: 16px auto 0;

      font-size: 20px;
      line-height: 100%;
    }
  }

  .sw-modal__fields {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 40px;

    @include media-down(tablet) {
      gap: 8px;
      margin-top: 20px;
    }

    .form__input {
      z-index: 2;

      ::v-deep input:not(.phone-field__input) {
        width: 100%;
        color: $black;
        font-size: 20px;
        letter-spacing: 0.4px;
        border-radius: 15px;
        border: 2px solid $black;
        padding: 20px 18px 22px;
        height: auto;

        @include media-down(tablet) {
          padding: 16px 14px 17px;
          font-size: 18px;
          letter-spacing: 0.36px;
        }
      }
    }
  }

  .sw-modal__submit {
    margin-top: 20px;
    margin-bottom: 10px;
    border-radius: 15px;

    @include media-down(tablet) {
      margin-top: 8px;
      margin-bottom: 8px;
    }

    button {
      padding: 19px 20px 21px;
      border-radius: 15px;
      border: 2px solid $black;
      background-color: #b6b6ff;
      color: $black;
      font-variant-numeric: lining-nums proportional-nums;
      font-size: 20px;
      font-weight: 700;
      width: 100%;
      cursor: pointer;
      position: relative;

      @include media-down(mobile) {
        padding: 15px 10px 16px;
        font-size: 18px;
      }

      &:after {
        content: '';
        width: 7px;
        height: 21px;
        display: inline-block;
        background-image: url('/v2/main/form/button-arrow.svg');
        margin-left: 5px;
      }
    }
  }

  .sw-modal__privacy {
    text-align: center;
    color: $black;
    font-variant-numeric: lining-nums proportional-nums;
    font-size: 18px;
    font-weight: 600;
    line-height: 100%;

    margin-bottom: 2px;
    display: block;

    @include media-down(tablet) {
      text-align: center;
      margin-bottom: 36px;
      font-weight: 500;
    }

    a {
      font-size: 18px;
      display: inline-block;
      color: #4a88da;
    }
  }

  .sw-modal__social {
    margin-top: 36px;

    @include media-down(tablet) {
      margin-top: 18px;
    }

    &--text {
      margin-bottom: 20px;

      font-size: 24px;
      font-weight: 500;
      line-height: 31.2px;
      text-align: center;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      color: $black;

      @include media-down(tablet) {
        margin-bottom: 18px;

        font-size: 20px;
        line-height: 20px;
        font-weight: 600;
      }
    }
  }

  .sw-modal__overlay {
    position: absolute;
    z-index: 1;
    inset: 0;
    cursor: pointer;

    background-color: rgba(255, 255, 255, 0.6);
    backdrop-filter: blur(2.5px);
  }
}

.contacts {
  display: grid;

  @include media-down(tablet) {
    width: 100%;
  }

  &__top {
    display: flex;
    gap: 36px;

    margin-bottom: 40px;
  }

  &__logo-link {
    width: fit-content;

    transition: 0.2s ease-out;

    &:hover {
      opacity: 0.6;
    }
  }

  &__logo {
    display: block;

    width: 100%;

    @include media-down(tablet) {
      width: 111px;
    }
  }

  &__logo-skv {
    transition: 0.2s ease-out;

    @include media-down(tablet) {
      width: 35px;
    }

    &:hover {
      opacity: 0.6;
    }
  }

  &__phone {
    margin-bottom: 20px;

    width: fit-content;

    font-size: 35px;
    font-weight: 600;
    color: $white-color;

    transition: 0.2s ease-out;

    @include media-down(tablet) {
      justify-self: center;

      font-size: 25px;
    }

    &:hover {
      opacity: 0.7;
    }
  }

  &__email {
    margin-bottom: 40px;

    width: fit-content;

    font-size: 24px;
    font-weight: 500;
    color: $white-color;

    transition: 0.2s ease-out;

    @include media-down(tablet) {
      justify-self: center;

      margin-bottom: 30px;

      font-size: 18px;
    }

    &:hover {
      opacity: 0.7;
    }
  }

  &__msngr {
    @include media-down(tablet) {
      display: none;
    }
  }

  &__tx {
    margin-bottom: 12px;

    font-size: 20px;
    font-weight: 500;
    color: #a7a7a7;

    br {
      display: none;

      @include media-down(tablet) {
        display: block;
      }
    }
  }

  &__row-bottom {
    display: flex;
    justify-content: center;
    gap: 10px;
  }

  &__link {
    display: block;
  }

  &__soc-icon {
    position: relative;
    width: 50px;
    height: 50px;
    border: 2px solid $black;
    background-color: $black;
    border-radius: 10px;
    transition: 0.2s ease-out;
    box-shadow: 2px 2px 0 0 $black;

    &:hover {
      transform: translate(2px, 2px);
      box-shadow: none;
    }
  }
}
</style>
