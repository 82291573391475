import { render, staticRenderFns } from "./SchoolWayModal.vue?vue&type=template&id=64ef3d87&scoped=true"
import script from "./SchoolWayModal.vue?vue&type=script&lang=js"
export * from "./SchoolWayModal.vue?vue&type=script&lang=js"
import style0 from "./SchoolWayModal.vue?vue&type=style&index=0&id=64ef3d87&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "64ef3d87",
  null
  
)

export default component.exports